import Home from "./Home/Home";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Route, Routes, useLocation} from "react-router-dom";
import Hotel from "./Hotels/Hotels";
import Homestays from "./Homestays/Homestays";
import Machines from "./Machines/Machines";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import BB2301 from "./BB1/BB1";
import BB2302 from "./BB2/BB2";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./ReturnPolicy/ReturnPolicy";
import "./General.css"
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {HelmetProvider } from "react-helmet-async"
//import ScrollToTop from "../ScrollToTop";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with custom path and title
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: document.title // Use the current document's title as the custom title
    });
  }, [location]);

  return (
    <HelmetProvider>
      <Header/>

      <Routes>
        <Route path="/" element = {<Home />} />
        <Route path="/hotels" element = {<Hotel />}/>
        <Route path="/homestays" element = {<Homestays />}/>
        <Route path="/machines" element = {<Machines />} />
        <Route path="/machines/bb2301" element = {<BB2301 />} />
        <Route path="/machines/bb2302" element = {<BB2302 />} />
        <Route path="/about-us" element = {<AboutUs />} />
        <Route path="/contact-us" element = {<ContactUs />} /> 
        <Route path="/terms-of-use" element = {<TermsOfUse />} />
        <Route path="/privacy-policy" element = {<PrivacyPolicy />} /> 
        <Route path="/return-policy" element = {<ReturnPolicy />} /> 
        <Route path="*" element={<Home /> } />
      </Routes>

      <Footer/>
    </HelmetProvider>
  ) 
}


export default App;