import BB2301 from "../assets/BB-2301.png"
import BB2302 from "../assets/BB-2302.png"
import "./Machines.css"
import ContactUsField from "../ContactField"
import { Link } from "react-router-dom"
import SEOConfig from "../SEOConfig"

const Machines = () => {
  return (
    <div className="machine-list-container" style={{ paddingTop: 70 }}>
      {/*Adding react helmet here*/}
      <SEOConfig
        title='Machines | Belibox'
        description="Belibox offers different machines based on your needs. Choose the one that is right for you" />
      <h1 style={{textAlign: "center", marginBottom: 50}}>Our Products</h1>
      <div className="product-grid">
        <Product2301 />
        <Product2302 />
      </div>
      <ContactUsField />
    </div>

  )
}
//To add a H1 = Our Products / Machines
//Image > H2 > See More (Drop Down) > View (Button)
//Could make it dynamic? Tho only 2 products so far so I'll keep it manual adding for now
//Product Listing 1 == 2301, 2 == 2302

const Product2301 = () => {
  return (
    <div className="listing-container">
      <img className = "product-img" src={BB2301} alt="Model BB2301"/>
      <h2>BB-2301</h2>
      <Link to={"./bb2301"}>
        <button className="view-details">View</button>
      </Link>
    </div>
  )
}

const Product2302 = () => {
  return (
    <div className="listing-container">
      <img className = "product-img" src={BB2302} alt="Model BB2302" />
      <div className="product-desc">
        <h2>BB-2302</h2>
        <Link to={"./bb2302"}>
          <button className="view-details">View</button>
        </Link>
      </div>

    </div>
  )
}

export default Machines;