import "./ContactUs.css"
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io"
import { useState, useEffect, useRef } from "react"
import SEOConfig from "../SEOConfig";


const SubmitModal = props => {
  if (!props.isActive) {
    return null
  }
  return (
    <div className="modal" onClick={props.onClose }>
      <div className="modal-container" onClick={e => e.stopPropagation() }>
        <div className="modal-header">
          {/*Modal Header*/}
          <h2>Request Sent</h2>
        </div>

        <div className="modal-content">
          {/*Modal Message*/}
          <p>Your message has been successfully sent. We will get back to you soon.</p>

          <button className="close-modal-button" onClick={props.onClose } >Close</button>
        </div>
      </div>
    </div>
  )
}

const ContactUs = () => {
  const [isActive, setActive] = useState(false);
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const selectRef = useRef(null);


  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = {
      name: form.elements.name.value,
      phone: form.elements.phone.value,
      email: form.elements.email.value,
      reason: form.elements.reason.value,
      message: form.elements.message.value
    };
    //const formData = new FormData(form);

    var fetchOptions = {
      headers: { 
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    }

    fetch('brevoemail', fetchOptions)
      .then(res => {
        if (res.ok) {
          console.log("Works")
          setActive(true)
          event.target.reset();
        } else {
          console.log("Error")
          event.target.reset();
        }
      })
      .then(console.log(formData))
      .then(response => response.text())
      .catch(error => console.log('error', error))
      //.catch(error => console.log("Works fr fr"));

  };
  return (
    <>
      <SEOConfig
        title="Contact Our Sales Team | Belibox"
        description="Got any questions? Contact the Belibox sales team for any enquiries."/>
      <div className="contact-container">
        <div className="contact-heading">
          <h1 style={{ fontSize: 48 }}>Get in touch with us</h1>
          <p style={{ fontSize: 24 }}>Fill out your information and one of our sales representative
            will reach out to you
          </p>
        </div>

        <div className="form-container">
          <form
            onSubmit={handleSubmit}
            method="POST">

            <div className="form-content">
              <div className="form-first-row">
                <div className="form-row" style={{}}>
                  <label htmlFor="name">Name : *</label>
                  <input ref={nameRef} name="name" id="name" type="text" autocomplete="off" placeholder="Enter your name..." minlength="3" maxlength="30" required />
                </div>

                <div className="form-row" style={{}}>
                  <label htmlFor="phone">Phone Number : </label>
                  <input ref={phoneRef} name="phone" id="phone" type="tel" autocomplete="off" placeholder="Eg. 0123456789 or 012-345-6789" pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4,5}" />
                </div>
              </div>

              <label htmlFor="email">Email : *</label>
              <input ref={emailRef} name="email" id="email" type="email" autocomplete="off" placeholder="Email..." required />

              <label htmlFor="reason">Reason for Contact : *</label>
              <select ref={selectRef} id="reason" name="reason">
                <option sele disabled="disabled" value="">---Please Select A Reason for Contact---</option>
                <option value="I would like to know more">I would like to know more about Belibox</option>
                <option value="I am interested in buying">I&apos;m interested in buying a machine</option>
                <option value="I would like for a demo">I would like to schedule a demo</option>
                <option value="Other...">Other...</option>
              </select>

              <label htmlFor="message">Messages : </label>
              <textarea ref={messageRef} name="message" id="message" rows={5} type="text" placeholder="Enter your message here..." maxLength="250" />

              <button type="submit">Submit</button>
            </div>
          </form>

          <div className="direct-contact">
            <h2>Contact Us Directly</h2>
            
            <div className="direct-contact-content">
              <div className="maps-container">
                <a href="https://maps.app.goo.gl/ayj1HR6Tqu6PAuLR7" target="_blank" rel="noopener noreferrer">
                  <img
                    className="google-maps-api"
                    src="https://maps.googleapis.com/maps/api/staticmap?center=Setiawalk%2CPuchong&zoom=14&scale=2&size=500x500&maptype=roadmap&format=jpg&key=AIzaSyC1LzelEIYNuvgQVD-0n5NmWZgpNpwi9lQ&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7CSetiawalk%2CPuchong" alt="Google map of Setiawalk,Puchong"
                  />
                </a>
              </div>

              <div className="direct-contact-wording">

                <div className="contact-info-fields">
                  <FaPhone className="wording-icons"/> 
                  <p>
                    +60126630088
                  </p>
                </div>

                <div className="contact-info-fields">
                  <FaLocationDot className="wording-icons" />
                  <p>
                    Outside Box Solutions Sdn. Bhd. <br></br>
                    Unit 6, Level 4, SetiaWalk Mall (Block K),<br></br>
                    47160 Puchong, Selangor, Malaysia
                  </p>
                </div>

                <div className="contact-info-fields">
                  <IoMdMail className="wording-icons" />
                  <p>
                  sales@belibox.my
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SubmitModal isActive={isActive} onClose={() => setActive(false) } />
      </div>
    </>
  )
}

export default ContactUs;

/*const handleSubmit = async (event) => {
  event.preventDefault();

  const form = event.target;
  const formData = new FormData(form);

  try {
    const response = await fetch('BrevoEmail/SendEmail', {
      method: 'POST',
      body: formData, // Send the FormData object directly
    });

    console.log(fetch('/brevoemail'));

    if (response.ok) {
      console.log("Okay");
      // Email sent successfully, handle success message or redirection
    } else {
      // Handle error response
      console.log("Gojover");
      console.log(formData);
    }
  } catch (error) {
    // Handle network errors or exceptions
  }
};*/