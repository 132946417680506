import SEOConfig from "../SEOConfig";
import "../TermsOfUse/TermsOfUse.css"

const ReturnPolicy = () => {
  return (
    <div className="policy-container">
      <SEOConfig
        title="Return Policy | Belibox"
        description="" />
      <div className="policy-heading">
        <h1>Returns & Refund Policy</h1>
        <hr />
      </div>

      <div className="policy-content">
        <p>
          Outside Box Solutions Sdn. Bhd.(OBS) provides the vending machine and the application software to our merchants. 
          We do not control or decide what items/products are sold in the vending machines. 
          The items and item pricing are determined by the vending machine merchant/owner/operator.
        </p>

        <h2>Returns</h2>
        <p>
        Application for the return of a purchased item due to item defects, damages, 
        or other issues should be brought up to the merchant/owner/operator of the vending machines. 
        Users may communicate with each other to resolve their 
        differences or approach their relevant local authorities to assist them in overcoming any dispute. 
        </p>

        <h2>Refunds</h2>
        <p>
          Application for refund through OBS may be done via contacting us through webmaster@belibox.my or 03-8602 2075.
        </p>

        <p>
          Refunds allowed are subject to the circumstance where payment has been made via the BeliBox app but the vending machine failed to dispense the item. 
          If any refund is approved, OBS will request for your local bank details (Malaysia only) and the refund will be transferred to you within 15 working days.
        </p>

        <hr />
      </div>
    </div>
  )
}

export default ReturnPolicy;