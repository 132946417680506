import './Footer.css'
import Belibox from "../assets/belibox.png";
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='f-container'>
      <footer className='footer'>
        <div className='footer-section-l'>
          <Link to={"/"}>
            <img className="footer-logo" src= {Belibox} alt="Belibox logo"/>
          </Link>
          <p className='company-desc'>Belibox is brought to you by Outside Box Solutions Sdn Bhd and its pool of industry experts of IT engineers and technicians, 
            with a mission to revolutionize the vending machine 
            industry with more future proof services and robustness to offer.
          </p>

          <hr></hr>

          <div className='socials'>
            <a href='https://www.facebook.com/belibox.obs' target='_blank' rel="noopener noreferrer">
              <FaFacebook className='react-icons'/>
                      </a>
                      <a href='https://www.instagram.com/belibox.my' target='_blank' rel="noopener noreferrer">
                          <FaInstagram className='react-icons' />
                      </a>
            <FaYoutube className='react-icons-null'/>
          </div>
        </div>
        <div className="footer-section-r">
          <div className='sections'>
            <h2>Machines</h2>
            <nav>
              <ul className='section-items'>
                <li><Link to = {"/machines/bb2301"}>Model BB-2301</Link></li>
                <li><Link to = {"/machines/bb2302"}>Model BB-2302</Link></li>
              </ul>
            </nav>
          </div>

          <div className='sections'>
            <h2>Markets</h2>
            <nav>
              <ul className='section-items'>
                <li><Link to={"/hotels"}>Hotels</Link></li>
                <li><Link to={"/homestays"}>Homestays</Link></li>
              </ul>
            </nav>
          </div>

          <div className='sections'>
            <h2>Support</h2>
            <nav>
              <ul className='section-items'>
                  <li><Link to={"/terms-of-use"}>Terms of Use</Link></li>
                  <li><Link to = {"/privacy-policy"}>Privacy Policy</Link></li>
                  <li><Link to = {"/return-policy"}>Return Policy</Link></li>
                  <li><Link  to={"/contact-us"}>Contact Us</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
      <small>Outside Box Solutions Sdn Bhd. &#169; 2023 | All Rights Reserved </small>
    </div>
  )
}

export default Footer