import BB2301 from "./assets/belibox-combined.png"
import { Link } from "react-router-dom"

const ProductsNav = () => {
  return (
    <div className='home-product-container'>
      <img className="product-nav-img" src={BB2301} alt = "Belibox machine BB2301 and BB2302 placed together." />
      <Link to={"/machines"}>
       <button className='home-view-machines'>View Machines</button>
      </Link>
    </div>
  )
}

export default ProductsNav