//import { Link } from "react-router-dom";
import ContactUsField from "../ContactField";
import "./Homestays.css"
import HomestayImg1 from "../assets/homestay-pic1.jpg"
import CashlessPayment from "../assets/e-wallet-payment-white.png"
import BB2301 from "../assets/BB-2301.png"
import ProductsNav from "../ProductsNav";
import PromoVideo from "../assets/Video/BeliboxPromoVid.mp4"
import SEOConfig from "../SEOConfig";
import HomestayRoom from "../assets/homestay-room-w-machine.jpg"
import StockedMachine from "../assets/belibox-stocked.png"

const Banner = () => {
  return(
    <div className='homestay-banner'>
      <h2 className='homestay-headline'>
        Homestay
      </h2>
      <p className='homestay-slogan'>
        Hosting made better. Transform convenience into profits.
      </p>
    </div>
  )
}

const Media = () => {
  return (
    <div className="video-container">
      <video className='video-content' playsinline autoPlay muted loop>
        <source src={PromoVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

const MainContent = () => {
  return (
    <div style={{paddingTop: 60}}>
      <div className="homestay-main-content-1">
        <h2>Additional Profits through convenience</h2>
        <p>Your guests will have sudden cravings at any moment, yet often their only option available is to go to nearby stores, 
          going out of their way to walk or drive to fulfill their needs. 
          This is both inconvenient for them and also a lost opportunity for you to gain additional income by fulfilling their wants.
        </p>
        <p>  
          With Belibox, our micro-vending machine is the solution for you to sell products and profit while leaving a better experience for your guests, 
          at an ultra affordable price.
        </p>
      </div>


      <div className="homestay-main-content-2 first">
        <div className="main-content-2-wording">
        <h2>Vending Machines, at a fraction of the cost</h2>
        <p>Traditional vending machines are very hefty purchase, with prices averaging <span style={{fontWeight: "bold"}}>RM20k+ per piece</span>, it is not a recommended purchase for homestay owners. 
          It would take a very long time before you earn back your investment.
        </p>
        <p>
          A single Belibox mico-vending machine costs less than RM 800*, requires no installation costs and does not incur any sort of utility fees. 
          You’ll be able to start generating profits in no time at all.
        </p>
        </div>
        <img className="homestay-image-machine" src={StockedMachine} alt = "A belibox machine stocked with items"/>
      </div>

      <div className="homestay-main-content-2">
        <img  className="homestay-image" src={HomestayRoom} alt = "Belibox BB2302 placed in a room"/>
        <div className="main-content-2-wording">
        <h2>A storefront made for you</h2>
        <p>Selling products on your own can be complicated, from deciding what to sell, 
          how to sell them and how to receive or verify payments from guests.
        </p>
        <p>
          At Belibox, we also provide you with a merchant portal where all the necessary setup can be done.
        </p>

        <p>
          <span style={{fontWeight: "bold"}}>Everything from creating products, to setting up templates and viewing sales details, all can be done through our merchant portal.</span>
        </p>
        </div>
      </div>

      <div className="homestay-main-content-2 last">
        <div className="main-content-2-wording">
        <h2>Secure, cashless system</h2>
        <p>Worried about theft due to our small, lightweight design? No need. 
          Belibox is designed so that only you are able to freely access machines that you own.
        </p>

        <p>
         Payment made through Belibox is also completely cashless, which is both more secure and more convenient for both you and your guests.
        </p>
        </div>
        <img style={{ objectFit: 'scale-down' }} className="homestay-image" src={CashlessPayment} alt="List of available e-payment options"/>
      </div>
    </div>
  )
}

const Homestays = () => {
  return(
    <div style={{ paddingTop: 70 }}>
      <SEOConfig 
        title="Belibox for Homestays | Belibox"
        description="Hosting made better. Transform convenience into profits."/>
      <Banner />
      <Media />
      <MainContent />
      <h2 className="homestay-heading-2">Check out our products here</h2>
      <ProductsNav />
      <ContactUsField />
    </div>
  )
}

export default Homestays;