import "./Hotels.css"
import HotelRoom from "../assets/hotel-room.jpg"
import { RiBillFill } from "react-icons/ri";
import { FaTools, FaWifi } from "react-icons/fa";
import { FaBoltLightning, FaLocationDot } from "react-icons/fa6";
import TallHotel from "../assets/tall-machine.jpg" 
import ProductsNav from "../ProductsNav";
import ContactUsField from "../ContactField";
import SEOConfig from "../SEOConfig";
import HotelRoomMachine from "../assets/hotel-room-w-machine.jpg"

const Banner = () => {
  return(
    <div className='hotel-banner'>
      <h1 className='hotel-headline'>
        Hotels
      </h1>
      <p className='hotel-slogan'>
        Satisfy the needs of your guests, from the comforts of their room.
      </p>
    </div>
  )
}

const MainContent1 = () => {
  return (
    <div className="notes-1-container">
      <div className="key-notes-1">
        <h2 className="notes-1-heading">
          Moving vending from public to private spaces
        </h2>

        <p>
          Traditionally, vending machines in hotels are placed in the hallways or lobby, for everyone to access.  This isn’t necessary the best option as:
        </p>

        <ul>
          <li>
          Guests will need to go out of their way to access these machines.
          </li>

          <li>
            There are some products that guests might not want to be seen in the open buying.
          </li>

          <li>
            Traditional vending machines have high costs and a lot of upkeep is required.
          </li>
        </ul>

        <p>
          Belibox allows you to provide your guests the convenience and privacy for a vending machine they can access straight from their rooms, at a fraction of the costs.
        </p>
      </div>
      <div className="notes-1-img">
        <img className="hotel-img-1" src={HotelRoomMachine} alt="Belibox machine placed in a hotel room" />
      </div>
    </div>
    
  )
}

const MainContent2 = () => {
  return (
    <div className="notes-2-container">
      <div className="hotel-img-container">
        <img className="hotel-img-2" src={TallHotel} alt="Belibox BB2301 Model" />
      </div>
      
      <div className="key-notes-2">
        <h2>0 Installation, 0 Expense, 0 Worries</h2>
        <p>
          With vending machines, the upfront cost is only one of the many things to consider. 
        </p>

        <p>
          Installation fees, placement and ultility bills are also big factors that can add up over time.
        </p>

        <p>With Belibox, we can help you remove these problems:</p>

        <div className="hotel-points">
          <p><RiBillFill/>Installation Fees</p>
          <p><FaTools/>Maintenance</p>
          <p><FaBoltLightning/>Electricity Bills</p>
          <p><FaWifi/>WiFi Usage</p>
          <p><FaLocationDot/>Placement</p>
        </div>
        <p>
          Small, lightweight without any cables, 
          you can place it wherever you want in your room, no installation needed.
        </p>

        <p>
          Belibox vending machines are battery powered, lasting up to a year while not incurring any electricity costs. 
        <p>
          No wifi needed as well as connectivity is done through Bluetooth.
        </p>
        </p>
      </div>
    </div>
  )
}

const Hotel = () => {
  return (
    <div style={{ paddingTop: 70 }}>
      <SEOConfig
        title="Belibox for Hotels | Belibox"
        description = "Satisfy the needs of your guests, from the comforts of their homes."/>
      <Banner />
      <MainContent1 />
      <MainContent2 />
      <h2 className="hotels-heading2">Check out what we have to offer</h2>
      <ProductsNav />
      <ContactUsField />
    </div>
  )
}

export default Hotel;