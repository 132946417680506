import '../General.css'
import '../Home/Home.css'
//import '../Testing.css'
import DigtialPayment from "../assets/e-wallet-payment.png"
import Portable from "../assets/Portable.png"
import BB2301 from "../assets/BB-2301.png"
import Revenue from "../assets/combined.png"
import { Link } from 'react-router-dom'
import ContactUsField from '../ContactField'
import Karex from "../assets/Karex_logo.png"
import Eureka from "../assets/eureka-logo.png"
import Bieu from "../assets/bieu.png"
import Hurix from "../assets/Hurix.png"
import ProductsNav from '../ProductsNav'
import VendingMachine from "../assets/vending-machine.png"
import SEOConfig from '../SEOConfig'
import PromoVideo from "../assets/Video/BeliboxPromoVid.mp4"
import StockedSmall from "../assets/belibox-stocked-small.png"
import PhoneApp from "../assets/phone-w-app.png"

const Banner = () => {
  return (
    <div className='home-banner'>
      <h1 className='headline'>
          Reinventing <br/> 
          Vending
      </h1>
      <p className='slogan'>
        Micro-vending machine for hotels and homestays <br style={{margin: 5}} /> 
        Sell products to your guests 24/7 from the comforts of your rooms
      </p>

      <div className='linked-buttons'>
        <Link to={"/machines"}>
          <button className='our-machines'>Our Machines</button>  
        </Link>
        <Link to={"/contact-us"}>
          <button className='request-demo'>Request Demo</button> 
        </Link>

      </div>
      
    </div>
  )
}

const SellingPoints = () => {
  return (
    <div className='selling-points'>
{/*Left */}
      <div className='points-container left'>
        <div className='points'>
          <h2 className='points-title'>No Electricity Needed</h2>
          <p className='points-desc'>Our machines are <span style={{color: "white"}}>fully battery powered.</span>  Eliminating any electricity costs and allows greater flexibility in positioning.</p>
        </div>

        <div className='img-container'>
          <img className='points-img' src={BB2301} alt="Display of Belibox BB2301 Model"/>
        </div>
      </div>
{/*Right */}
      <div className='points-container right'>
        <div className='img-container'>
          <img style={{ objectFit: 'scale-down' }} className='points-img' src={PhoneApp} alt="Screenshot of Belibox App"/>
        </div>

        <div className='points'>
          <h2 className='points-title'>Completely Offline</h2>
          <p className='points-desc'>Belibox is <span style={{color: "white"}}>powered by Bluetooth</span> and requires no connection to the Internet.</p>
        </div>
      </div>
{/*Left */}
      <div className='points-container left'>
        <div className='points'>
          <h2 className='points-title'>Ultra Affordable</h2>
          <p className='points-desc'><span style={{color: "white"}}>98% Lower Capex</span> than traditional vending machines. Turn your investment into profits in no time.</p>
        </div>

        <div className='img-container'>
          <img className='points-img' style={{ objectFit: 'scale-down' }} src={Revenue} alt="Machine Display with info on cost benefits"/>
        </div>
      </div>
{/*Right */}
      <div className='points-container right'>
        <div className='img-container'>
          <img className='points-img' src={StockedSmall}/>
        </div>
        
        <div className='points'>
          <h2 className='points-title'>Portable Design</h2>
          <p className='points-desc'>Our machines are designed to be <span style={{color: "white"}}>small and lightweight,</span> allowing you to put it anywhere you like.</p>
        </div>
      </div>
{/*Left */}
      <div className='points-container left'>
        <div className='points'>
          <h2 className='points-title'>Cashless Payment</h2>
          <p className='points-desc'>All transactions will be done through <span style={{color: "white"}}>online payment,</span> removing the need for cash, for both security and convenience.</p>
        </div>

        <div className='img-container'>
          <img className='points-img' src={DigtialPayment} alt="A collection of digital payment options for Belibox machines"/>
        </div>
      </div>
    </div>
  )
}

const Media = () => {
  return (
    <div className='video-container'>
      <video className='video-content' playsinline autoPlay muted controls>
        <source src={PromoVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

const Partners = () => {
  return (
    <div style={{backgroundColor: "#DDE3FF"}}>
      <h2 className='partner-title'>Our Product Partners</h2>
      <div className='partner-container'>
        <img className='partner-logo' src={Hurix} alt="Hurix Logo" />
        <img className='partner-logo' src={Bieu} alt="Bieu Logo" />
        <img className='partner-logo' src={Eureka} alt="Eureka Logo" />
        <img className='partner-logo' src={Karex} alt="Karex Logo" />
      </div>
    </div>
  )
} 

const MainContent = () => {
  return (
    <div style={{ paddingTop: 70 }}>
      <SEOConfig 
       title="Micro Vending Machine: Sell Anything, Anywhere | Belibox"
        description="Belibox is a micro vending machine suitable for Hotel rooms, Airbnbs or ANY spaces you can imagine to generate ADDITIONAL sales."/>
      <Banner />
      <div className='content-container'>
        <Media />

        <h2 className='heading2-1'>What sets us apart</h2>
        <SellingPoints />

        <h2 className= 'heading2-2'>Check out what we&apos;re offering</h2>
        <ProductsNav/>
        <Partners />
        <ContactUsField />
      </div>

    </div>
)
}

export default MainContent;