import { Link } from "react-router-dom"

const ContactUsField = () => {
  return(
    <div className='contact-field'>
      <h2>Bring your establishment to the next level</h2>
      <Link to={"/contact-us"}>
        <button className='contact-field-button'>Contact Us</button>
      </Link>
    </div>
  )
}

export default ContactUsField