import "./TermsOfUse.css"
import React from 'react'
import SEOConfig from "../SEOConfig"

const TermsOfUse = () => {
  return (
    <div className="policy-container">
      <SEOConfig
        title="Terms of Use | Belibox"
        description=""/>
      <div className="policy-heading">
        <h1>
          Terms of Use Policy & Privacy Policy
        </h1>
        <hr />
      </div>

      <div className="policy-content">
        <p>
          Welcome to www.belibox.my (the “Website”). This Terms of Use Policy (“Policy”) governs your use of the website and services provided therein.
          By accessing or using the Website, you agree to be bound by this Policy. If you do not agree with any part of this Policy, please refrain from using the Website.      
        </p>

        <h2>
          Acceptance of Terms
        </h2>
        <p>
          By accessing and using the website, you acknowledge that you have read, understood and agree to be bound by this policy,
          as well as any additional terms and conditions referenced herein or made available on this website.
        </p>

        <h2>Intellectual Property Rights</h2>
        <p>All content and materials available on the Website, including but not limited to text, graphics, logos, images, videos, and software, 
          are the property of BeliBox or its licensors and are protected by applicable intellectual property laws. 
          You may not use, reproduce, modify, distribute, or exploit any content from the Website without obtaining prior written permission from the respective rights holders.</p>

        <h2>Use of Website</h2>
        {/*TO BE ADDED WITH LIST STYLING */}
        <h3>4.1 User Conduct</h3>
        <p>You agree to use the Website for lawful purposes only and in a manner that does not violate any applicable laws or regulations. You shall not engage in any of the following activities:</p>
        <ol>
          <li>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with any person or entity.</li>
          <li>Uploading, transmitting, or distributing any content that is unlawful, harmful, defamatory, obscene, pornographic, or otherwise objectionable.</li>
          <li>Attempting to gain unauthorized access to the Website or its related systems or networks.</li>
          <li>Interfering with or disrupting the integrity or performance of the Website or its services.</li>
          <li>Engaging in any activity that could harm, disable, overburden, or impair the Website or interfere with any other user&apos;s experience.</li>
        </ol>

        <h3>User Account</h3>
        <p>Certain features or services on the Website may require you to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that
            occur under your account. You agree to provide accurate, current, and complete information during the registration process and to promptly update any changes to your account information.</p>

        <h2>4.2 Privacy</h2>
        <p>
          Your use of the Website is subject to our Privacy Policy, which explains how we collect, use, and protect your personal information.
          By using the Website, you consent to the collection and use of your information as described in the Privacy Policy.
        </p>

        <h2>Third-Party Links and Content</h2>
        <p>
          The Website may contain links to third-party websites or resources that are not owned or controlled by BeliBox. 
          We do not endorse or assume any responsibility for the content, privacy policies, or practices of third-party websites.
          You acknowledge and agree that BeliBox shall not be liable for any loss or damage caused by your use of any third-party websites or resources.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by applicable law, BeliBox and its affiliates, officers, directors, employees, agents, 
          and licensors shall not be liable for any direct, indirect, incidental, 
          special, or consequential damages arising out of or in any way connected with your use of the Website or its services.
        </p>

        <h2>Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless BeliBox and its affiliates, officers, directors, employees, agents, 
          and licensors from any claims, losses, damages, liabilities, and expenses, including reasonable attorneys&apos; fees, 
          arising out of or in connection with your use of the Website, violation of this Policy, 
          or any violation of applicable laws or rights of any third party.
        </p>

        <h2>Modifications to the Policy</h2>
        <p>
          BeliBox reserves the right to modify or update this Policy at any time without prior notice. 
          Any changes will be effective immediately upon posting of the revised Policy on the Website. 
          Your continued use of the Website after any modifications to this Policy constitutes your acceptance of such changes.</p>

        <h2>Governing Law and Jurisdiction</h2>
        <p>This Policy shall be governed by and construed in accordance with the laws of Malaysia.</p>

        <h2>Severability</h2>
        <p>
          If any provision of this Policy is found to be invalid, illegal, or unenforceable, 
          the remaining provisions shall continue in full force and effect.
        </p>

        <h2>Entire Agreement</h2>
        <p>This Policy constitutes the entire agreement between you and BeliBox regarding your use of the Website and supersedes any prior or contemporaneous understandings or agreements.</p>
        <p>If you have any questions or concerns about this Policy, please contact us at webmaster@belibox.my.</p>
        <p>By using the Website, you acknowledge that you have read, understood, and agree to be bound by this Terms of Use Policy.</p>

        <hr />
      </div>
    </div>
  )
}

export default TermsOfUse;