import Belibox from "../assets/belibox.png";
import './Header.css';
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa"
import { useState } from "react";


function Header() {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <header>
            <nav className="nav">
                <Link to= "/">
                    <img className="logo" src= {Belibox} alt = "Belibox Logo"/>
                </Link>
                <div className={isExpanded ? "nav-links expanded" : "nav-links"}>
                    <ul className="nav-items">
                        <li onClick={() => {
                            setIsExpanded(!isExpanded)
                        }}><Link to = "/machines" style={{ textDecoration: 'none'}}>Machines</Link></li>
                        <li onClick={() => {
                            setIsExpanded(!isExpanded)
                        }}><Link to = "/hotels" style={{ textDecoration: 'none' }}>Hotel</Link></li>
                        <li onClick={() => {
                            setIsExpanded(!isExpanded)
                        }}><Link to = "/homestays" style={{ textDecoration: 'none'}}>Homestays</Link></li>
                            {/*<li onClick={scrollToFooter}>About Us</li>*/}
                        </ul>
                    <Link className="contact-button" to="/contact-us" onClick={() => {
                        setIsExpanded(!isExpanded)
                    }}>
                        Contact Us
                    </Link>
                    <FaBars className="header-icon" onClick={() => {
                        setIsExpanded(!isExpanded)
                    }} />
                </div>
            </nav>
        </header>
    )
}

function scrollToFooter() {
    window.scrollTo(0, document.body.scrollHeight);
}

export default Header