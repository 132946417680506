import machinepic from "../assets/BB-2302.png"
import "../BB1/MachineDetails.css"
import { AiOutlineDownload, AiOutlineScan, AiFillHeart } from "react-icons/ai"
import { PiHandTap, PiClockFill } from "react-icons/pi"
import { MdPayments } from "react-icons/md"
import ContactUsField from "../ContactField"
import MerchantPortal from "../assets/combined-website.png"
import App from "../assets/phone-w-app-combined.png"
import SEOConfig from "../SEOConfig"
import ImageReference from "../assets/image-reference-small.jpg"
import { Link } from "react-router-dom"

const BB2302 = () => {
  return (
    <div style={{ paddingTop: 60 }}>
      <SEOConfig
        title="Belibox Model BB2302 | Belibox"
        description=""/>
      <Specs />
      <SizeReference />
      <HowItWorks />
      <InAddition />
      <ContactUsField />
    </div>
  )
}

const Specs = () => {
  return (
    <div className="product-specs">
      <div className="machine-image">
       <img className="info-img-2" src={machinepic} alt = "A Belibox Machine Model" />
      </div>

      <div className="spec-table-container">
        <h1>Model BB-2302</h1>
        <div className="spec-details">
          <h2>Specification</h2>
          <hr/>

          <div className="spec-block">
            <h3>Dimensions (W x H x D) :</h3>
            <p>360mm x 222mm x 100mm</p>
          </div>
          <hr/>

          <div className="spec-block">  
            <h3>Doors :</h3>
            <p>5</p>
          </div>
          <hr/>

          <div className="spec-block">
            <h3>Material :</h3>
            <p>ABS</p>
          </div>
          <hr/>

          <div className="spec-block">
            <h3>Power :</h3>
            <p>Rechagable 3.7v Lithium ion</p>
          </div>
          <hr/>

          <div className="spec-block">
            <h3>Operation Mode :</h3>
            <p>Bluetooth BLE 2.4GHZ</p>
          </div>
          
          <div className="mac-button-container">
            <Link to={'/contact-us'}>
              <button className="buy-now">Buy Now</button>
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}

const SizeReference = () => {
  return (
    <>
      <h2 className="size-reference-heading">Size Reference</h2>
      <div className="size-reference-container">
        <div className="size-reference-img-2">
          <img className="sr-image" src={ImageReference} alt="Belibox BB2302 Model" />
        </div>

        <div className="size-reference-list">
          <p><span style={{fontWeight: "bold"}}>Examples of items that can be stocked include, but aren&apos;t limited to : </span></p>
          <ol>
            <li> 1x Condom Packet</li>
            <li> 1x Playing Cards</li>
            <li> 3x Small Powdered Drink Sachets</li>
            <li> 1x 70g Small Canned Snacks</li>
            <li> 1 - 2x Small Packed Snacks</li>
          </ol>
        </div>
      </div>
    </>
  )
}

const HowItWorks = () => {
  return (
    <div style={{paddingTop: 60}}>
        <h2 className="size-reference-heading">How it works</h2>
    <div className="steps-grid">
      <div className="steps-grid-item">
        <AiOutlineDownload className="react-icons-machine" />
        <h3>Step One</h3>
        <p>Download Belibox App</p>
      </div>

      <div className="steps-grid-item">
        <AiOutlineScan className="react-icons-machine" />
        <h3>Step Two</h3>
        <p>Scan QR</p>
      </div>

      <div className="steps-grid-item">
        <PiHandTap className="react-icons-machine" />
        <h3>Step Three</h3>
        <p>Select Items</p>
      </div>

      <div className="steps-grid-item">
        <MdPayments className="react-icons-machine" />
        <h3>Step Four</h3>
        <p>Select Payment Methods</p>
      </div>

      <div className="steps-grid-item">
        <PiClockFill className="react-icons-machine" />
        <h3>Step Five</h3>
        <p>Wait for the door to open</p>
      </div>

      <div className="steps-grid-item">
        <AiFillHeart className="react-icons-machine" />
        <h3>Step Six</h3>
        <p>Take out your item and enjoy</p>
      </div>
    </div>
    </div>
  )
}

const InAddition = () => {
  return (
    <>
      <h2 className="size-reference-heading">In Addition</h2>
      <div className="machine-info-container-blue">
        <div >
          <h3>Managing made easier with dedicated Merchant Portal</h3>
          <p>Manage all of your items with our dedicated merchant portal. Set product prices, templates and view sales details all through our powerful merchant website.</p>
        </div>
        <img className="in-addition-img" src={MerchantPortal} alt="Belibox Merchant Management Website" />
      </div>

      <div className="machine-info-container-white">
        <img style={{ objectFit: "scale-down" }} className="in-addition-img" src={App} alt="Screenshots of the Belibox Staff Application" />
        <div>
          <h3>Easy to use Staff Application</h3>
          <p>Staff Application provided for easy access to machine. Restock your items with a simple press of a button</p>
        </div>
      </div>
    </>
  )
}
export default BB2302;