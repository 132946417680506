import "../TermsOfUse/TermsOfUse.css"

import SEOConfig from "../SEOConfig";

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <SEOConfig
        title="Privacy Policy | Belibox"
        description="" />
      <div className="policy-heading">
        <h1>Privacy Policy</h1>
        <hr />
      </div>
      <div className="policy-content">
        <p>
          At www.belibox.my (the “Website”), we respect your privacy and are committed to protecting your personal information. 
          This Privacy Policy (“Policy”) explains how we collect, use, disclose, 
          and safeguard your personal data in compliance with the Personal Data Protection Act 2010 of Malaysia (“PDPA”). 
          By accessing or using the Website, you acknowledge that you have read, understood, and agree to be bound by this Policy. 
          If you do not agree with any part of this Policy, please refrain from using the Website.
        </p>

        <h2>Collection of Personal Data</h2>
        <h3>1.1 Information You Provide</h3>
        <p>
          We may collect personal information that you voluntarily provide to us when using the Website, 
          such as your name, email address, phone number, and any other information you choose to provide.
        </p>

        <h3>1.2 Information We Collect Automatically</h3>
        <p>
          When you access or use the Website, we may automatically collect certain information, including your IP address, browser type, operating system, referring URLs, and pages viewed.
          We may also use cookies and similar technologies to enhance your user experience and collect additional information about your usage patterns.
        </p>


        <h2>Use of Personal Data</h2>
        <p>We may use the personal data we collect for the following purposes:</p>
        <ul>
          <li>To provide and improve our services, products, and the Website’s functionality.</li>
          <li>To personalize your experience and tailor content and advertisements to your interests.</li>
          <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
          <li>To send you promotional materials, updates, and newsletters, subject to your preferences.</li>
          <li>To analyze and monitor usage patterns, conduct research, and perform data analytics.</li>
          <li>To comply with legal obligations and protect our rights, property, and safety.</li>
        </ul>

        <h2>Disclosure of Personal Data</h2>
        <p>We may disclose your personal data to third parties in the following circumstances:</p>
        <ul>
          <li>To service providers, contractors, and partners who assist us in operating the Website and delivering our services.</li>
          <li>To comply with legal obligations, including responding to lawful requests from government authorities or to protect our rights, property, or safety.</li>
        </ul>
        <p>In connection with a corporate transaction, such as a merger, acquisition, or sale of assets, where personal data may be transferred to the acquiring entity.</p>

        <h2>Security of Personal Data</h2>
        <p>
          We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, 
          disclosure, alteration, or destruction. 
          However, please note that no method of transmission over the internet or electronic storage is completely secure, 
          and we cannot guarantee absolute security.
        </p>

        <h2>Retention of Personal Data</h2>
        <p>
          We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Policy or as required by applicable laws. 
          We will also take reasonable steps to ensure that personal data is accurate, complete, and up to date.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have certain rights under the PDPA, including the right to access, correct, and request the deletion of your personal data. 
          You may also withdraw your consent for certain data processing activities. 
          To exercise your rights or for any inquiries regarding your personal data, please contact us at webmaster@belibox.my.
        </p>

        <h2>Third-Party Links and Services</h2>
        <p>
          The Website may contain links to third-party websites or services that are not owned or controlled by BeliBox. 
          This Policy applies solely to information collected by the Website, 
          and we are not responsible for the privacy practices or content of third-party websites. 
          We encourage you to review the privacy policies of those third parties before providing any personal data.
        </p>

        <h2>Amendments to the Policy</h2>
        <p>
          We reserve the right to modify or update this Policy at any time. 
          Any changes will be effective immediately upon posting the revised Policy on the Website. 
          Your continued use of the Website after any modifications to this Policy constitutes your acceptance of such changes.
        </p>

        <p>
          If you have any questions or concerns about this Policy, please contact us at webmaster@belibox.my.
        </p>

        <p>
          By using the Website, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
        </p>
        <hr />
      </div>
    </div>
  )
}

export default PrivacyPolicy;