import { Helmet } from 'react-helmet-async';
import React from 'react';

export default function SEOConfig({title, description }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description }/>
    </Helmet>
  )
}